import * as runtime from "$lib/paraglide/runtime";
import { createI18n } from "@inlang/paraglide-js-adapter-sveltekit";

export const i18n = createI18n(runtime, {
    exclude: ["/dashboard"],
    pathnames: {
        "/login": {
            no: "/login",
            en: "/login"
        },
        "/register": {
            no: "/kom-igang",
            en: "/get-started"
        },
    }
});